import React, { Component } from "react";

class Contact extends Component {
  render() {
    return (
      <section id="contact" className="section-contact">
        <div className="overlay-parallax">
          <div className="contact">
            <div className="container">
              <div className="row flex-column">
                <h2 className="heading-section" data-aos="fade-up">
                  Reach Me
                </h2>
                <div className="seperator"></div>
                <p className="sub-title" data-aos="fade-in">
                  Get in touch for collaborations or just say hi!
                </p>
                <div className="contact-us">
                  <div className="col-md-12">
                    <div className="data mydatas d-flex flex-row justify-content-around">
                      <p data-aos="zoom-in">
                        <i
                          className="fa fa-envelope fa-3x"
                          style={{ color: "#FCCF4D" }}
                        ></i>{" "}
                        jagath.gj@gmail.com
                      </p>
                      {/* <p>
                        <i
                          className="fa fa-mobile-phone fa-3x"
                          style={{color:"#FCCF4D",fontSize:"30px"}}
                        ></i>{" "}
                        (+91) 8157955899
                      </p> */}
                      <p data-aos="zoom-in">
                        <i
                          className="fa fa-home fa-3x"
                          style={{ color: "#FCCF4D" }}
                        ></i>{" "}
                        Trivandrum, Kerala, India
                      </p>
                    </div>
                  </div>
                  <div className="clear"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
