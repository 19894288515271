import React, { Component } from 'react'

class TopArrow extends Component {

    render() {
        const {showScrollTop} = this.props;
        return (
            <div className={showScrollTop && showScrollTop ? "to_top showme" : "to_top"} id="to_top" onClick={(e)=>this.props.onClick(e)}>
                <i className="fa fa-long-arrow-up" aria-hidden="true"></i>
            </div>
        )
    }
}

export default TopArrow
