import React from "react";

const RepoCard = ({ repo, language }) => {
  return (
    <div className="col-md-4 mt-3" data-aos="zoom-in">
      <div className="card repo-card h-100">
        <div className="card-body d-flex flex-column">
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-center repo-card-icon">
              <div className="fa fa-github fa-3x"></div>
            </div>
            <a
              href={repo.html_url}
              target="_blank"
              className="flex-fill ml-2 repo-card-title"
              rel="noopener noreferrer"
            >
              {repo.name}
            </a>
          </div>
          <p className="card-text">{repo.description}</p>
          <div className="d-flex align-items-center mt-auto repo-card-lang">
            {repo.language ? (
              <>
                <div className="mr-3 flex-fill d-flex align-items-center">
                  <div
                    style={{
                      backgroundColor: language[repo.language]["color"],
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                    }}
                  ></div>
                  <div className="ml-2">{repo.language}</div>
                </div>
              </>
            ) : null}
            <div className="ml-auto d-flex align-items-center">
              {repo.stargazers_count >= 0 ? (
                <>
                  <a
                    className="d-flex align-items-center repo-card-star"
                    href={repo.html_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={`${repo.stargazers_count} stargazers`}
                  >
                    <span className="fa fa-star"> </span>
                    <div className="ml-2">{repo.stargazers_count}</div>
                  </a>
                </>
              ) : null}
              {repo.forks_count >= 0 ? (
                <React.Fragment>
                  <a
                    className={"d-flex align-items-center ml-4 repo-card-fork"}
                    href={`${repo.html_url}/fork`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={`Forked ${repo.forks_count} times`}
                  >
                    <span className="fa fa-code-fork"></span>
                    <div className="ml-2">{repo.forks_count}</div>
                  </a>
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepoCard;
