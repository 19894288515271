import GitHubCards from "components/githubcards/GitHubCards";
import React, { Fragment, useState, useEffect, useCallback } from "react";
import "./App.scss";
import Aos from "aos";
import {
  TopArrow,
  Header,
  About,
  Expertise,
  Quotes,
  Contact,
  Footer,
  Portfolio,
  Loader,
  Context,
} from "./components";
// import ScrollAnimation from 'react-animate-on-scroll';

function App() {
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  let [nightMode, setNightMode] = useState(false);

  const nightModeToggler = () => {
    setNightMode(!nightMode);
  };

  const checkScrollTop = () => {
    if (!scrollTopVisible && window.pageYOffset > 400) {
      setScrollTopVisible(true);
    } else if (scrollTopVisible && window.pageYOffset <= 400) {
      setScrollTopVisible(false);
    }
  };

  const scrollTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  useEffect(() => {
    setIsLoading(false);
    Aos.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
      once: true,
    });
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <div
        className={
          nightMode && nightMode
            ? "absolute-scroller dark-mode"
            : "absolute-scroller"
        }
      >
        <Context
          handleNightMode={() => nightModeToggler()}
          nightModeStatus={nightMode}
        />
        <TopArrow
          onClick={(e) => scrollTop(e)}
          showScrollTop={scrollTopVisible}
        />
        <Header />
        <About />
        <Expertise />
        <Portfolio />
        <Quotes />
        <GitHubCards />
        <Contact />
        <Footer />
      </div>
    </Fragment>
  );
}

export default App;
