import React, { Component, Fragment } from "react";
import Typed from "typed.js";

class Typer extends Component {
  componentDidMount() {
    const options = {
      strings: [
        '<h3 aria-hidden="true" class="m-0">Front-End Developer</h3>',
        '<h3 aria-hidden="true" class="m-0">UI/UX Designer</h3>',
        '<h3 aria-hidden="true" class="m-0">Product Designer</h3>',
        '<h3 aria-hidden="true" class="m-0">React Developer</h3>',
      ],
      typeSpeed: 50,
      backSpeed: 50,
      loopCount: Infinity,
      smartBackspace: true,
      backDelay: 1500,
      loop: true,
    };
    this.typed = new Typed(this.el, options);
  }

  componentWillUnmount() {
    this.typed.destroy();
  }

  render() {
    return (
      <Fragment>
        <h3 className="typed-text d-flex align-items-center">
          <span className="span_color"></span>
          <span
            id="typed"
            className="d-flex align-items-center"
            ref={(el) => {
              this.el = el;
            }}
          ></span>
          <i
            className="fa fa-i-cursor typed-cursor typed-cursor--blink"
            aria-hidden="true"
          ></i>
        </h3>
      </Fragment>
    );
  }
}

export default Typer;
