import Particle from "components/particles/Particles";
import React, { Component } from "react";
import Img from "react-image";
import Logo from "../../assets/image/jjlogo.png";
import { Typer } from "../../components";

class Header extends Component {
  state = {
    openMenu: false,
  };

  handleOpenMenu = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      openMenu: !prevState.openMenu,
    }));
  };

  render() {
    const { openMenu } = this.state;
    return (
      <section id="home" className="slider">
        <div className="container h-100">
          <div className="row flex-column">
            <div className="navbar flex-nowrap">
              <div className="col-md-6 col-sm-6">
                <div className="brand">
                  <a
                    className="navbar-brand"
                    href="http://www.jagathjayakumar.com"
                  >
                    <Img src={Logo} className="img-fluid" />
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <a
                  href="#menu"
                  id="toggle"
                  className={openMenu ? "on" : ""}
                  onClick={(e) => this.handleOpenMenu(e)}
                >
                  <span></span>
                </a>
                <div id="menu">
                  <ul>
                    <li className="item">
                      <a data-scroll href="#home">
                        <i className="fa fa-home fa-lg" aria-hidden="true"></i>
                      </a>
                      <span>
                        <a data-scroll className="menu-item" href="#home">
                          Home
                        </a>
                      </span>
                    </li>

                    <li className="item">
                      <a data-scroll href="#about">
                        <i className="fa fa-user fa-lg" aria-hidden="true"></i>
                      </a>
                      <span>
                        <a data-scroll className="menu-item" href="#about">
                          About Me
                        </a>
                      </span>
                    </li>

                    <li className="item">
                      <a data-scroll href="#services">
                        <i
                          className="fa fa-server fa-lg"
                          aria-hidden="true"
                        ></i>
                      </a>
                      <span>
                        <a data-scroll className="menu-item" href="#services">
                          Expertise
                        </a>
                      </span>
                    </li>

                    <li className="item">
                      <a data-scroll href="#work">
                        <i
                          className="fa fa-paint-brush fa-lg"
                          aria-hidden="true"
                        ></i>
                      </a>
                      <span>
                        <a data-scroll className="menu-item" href="#work">
                          Portfolio
                        </a>
                      </span>
                    </li>

                    <li className="item">
                      <a data-scroll href="#contact">
                        <i
                          className="fa fa-envelope-o fa-lg"
                          aria-hidden="true"
                        ></i>
                      </a>
                      <span>
                        <a data-scroll className="menu-item" href="#contact">
                          Contact
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-12 header-content">
              <h3 className="text-1">Hello, I Am</h3>
              <div className="myname-wrapper d-flex">
                <h1 className="myname">
                  JAGATH
                  <br />
                  JAYAKUMAR
                </h1>
              </div>
              <Typer />
              <div
                className="quick-link-wrapper d-flex justify-content-between align-items-center"
                style={{ maxWidth: "320px" }}
              >
                <a
                  className={
                    openMenu
                      ? "btn quick-link btn-z d-inline-flex align-items-center justify-content-center"
                      : "btn quick-link d-inline-flex align-items-center justify-content-center"
                  }
                  href="https://www.facebook.com/jagath.gj"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Facebook"
                >
                  <i className="fa fa-facebook fa-2x" aria-hidden="true"></i>
                </a>
                <a
                  className={
                    openMenu
                      ? "btn quick-link btn-z d-inline-flex align-items-center justify-content-center"
                      : "btn quick-link d-inline-flex align-items-center justify-content-center"
                  }
                  href="https://medium.com/@jagathjayakumar"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Medium"
                >
                  <i className="fa fa-medium fa-2x" aria-hidden="true"></i>
                </a>
                <a
                  className={
                    openMenu
                      ? "btn quick-link btn-z d-inline-flex align-items-center justify-content-center"
                      : "btn quick-link d-inline-flex align-items-center justify-content-center"
                  }
                  href="https://www.linkedin.com/in/jagath-jayakumar-51458a26/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Linkedin"
                >
                  <i className="fa fa-linkedin fa-2x" aria-hidden="true"></i>
                </a>
                <a
                  className={
                    openMenu
                      ? "btn quick-link btn-z d-inline-flex align-items-center justify-content-center"
                      : "btn quick-link d-inline-flex align-items-center justify-content-center"
                  }
                  href="https://github.com/jagathgj"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Github"
                >
                  <i className="fa fa-github fa-2x" aria-hidden="true"></i>
                </a>
                <a
                  className={
                    openMenu
                      ? "btn quick-link btn-z d-inline-flex align-items-center justify-content-center"
                      : "btn quick-link d-inline-flex align-items-center justify-content-center"
                  }
                  href="https://jagathwrites.blogspot.in/"
                  target="_blank"
                  title="jagathwrites.blogspot.in"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-heart fa-2x" aria-hidden="true"></i>
                </a>
                <a
                  className={
                    openMenu
                      ? "btn quick-link btn-z d-inline-flex align-items-center justify-content-center"
                      : "btn quick-link d-inline-flex align-items-center justify-content-center"
                  }
                  href="https://www.zoet.app"
                  target="_blank"
                  title="zoetApp"
                  rel="noopener noreferrer"
                >
                  <i
                    className="fa fa-film fa-2x faa-rising animated faa-slow"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <Particle />
      </section>
    );
  }
}

export default Header;
