import React, { Component } from "react";
import mixitup from "mixitup";
import data from "../../data/data.json";
import ModalImage from "react-modal-image";
import { LazyLoadComponent } from "react-lazy-load-image-component";

const baseUrl = "/portfolio/";

class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.myMixItUp = React.createRef();
    this.state = {
      data: [],
    };
  }

  mixIt = () => {
    mixitup(this.myMixItUp.current);
  };

  async componentDidMount() {
    this.mixIt();
  }
  render() {
    return (
      <section id="work" className="section-work">
        <div className="mywork">
          <div className="container">
            <div className="row flex-column">
              <h2 className="heading-section" data-aos="fade-up">
                My Works
              </h2>
              <div className="seperator changed"></div>
              <p className="sub-title" data-aos="fade-in">
                Here, you can see some projects I have had honoured to work
                with. I divide it into six areas: Website design, UI/UX
                Designing, Mockups, Print design, Graphic Design {"&"} other
                creative jobs. These experiences helps me to create great
                products with client goals in mind.
              </p>
              <div className="button-mywork" data-aos="fade-in">
                <button className="work btn active filter" data-filter="all">
                  All
                </button>
                <button className="work btn filter" data-filter=".art">
                  3D Art
                </button>
                <button className="work btn filter" data-filter=".web">
                  Web
                </button>
                <button className="work btn filter" data-filter=".apps">
                  Apps
                </button>
                <button className="work btn filter" data-filter=".mockup">
                  UI Mockup
                </button>
                <button className="work btn filter" data-filter=".print">
                  Print
                </button>
                <button className="work btn filter" data-filter=".logo">
                  Logo
                </button>
                <button className="work btn filter" data-filter=".misc">
                  Others
                </button>
              </div>
            </div>
            <div id="Container" className="img-work row" ref={this.myMixItUp}>
              <div className="col-md-12 col-lg-12 d-flex flex-wrap">
                {data["images"]["art"].map((image, idx) => (
                  <React.Fragment key={idx}>
                    <div
                      className="col-md-3 col-sm-3 mix art"
                      data-aos="fade-in"
                    >
                      <div className="photo">
                        <LazyLoadComponent>
                          <ModalImage
                            alt={image.title}
                            small={`${baseUrl}${image.src}`}
                            large={`${baseUrl}${image.src}`}
                          />
                        </LazyLoadComponent>
                        {/* <div className="overlay">
                            <div className="zoom">
                              <p>View Image</p>
                            </div>
                          </div> */}
                      </div>
                    </div>
                  </React.Fragment>
                ))}
                {data["images"]["web"].map((image, idx) => (
                  <React.Fragment key={idx}>
                    <div
                      className="col-md-3 col-sm-3 mix web"
                      data-aos="fade-in"
                    >
                      <div className="photo">
                        <LazyLoadComponent>
                          <ModalImage
                            alt={image.title}
                            small={`${baseUrl}${image.src}`}
                            large={`${baseUrl}${image.src}`}
                          />
                        </LazyLoadComponent>
                        {/* <div className="overlay">
                            <div className="zoom">
                              <p>View Image</p>
                            </div>
                          </div> */}
                      </div>
                    </div>
                  </React.Fragment>
                ))}

                {data["images"]["app"].map((image, idx) => (
                  <React.Fragment key={idx}>
                    <div
                      className="col-md-3 col-sm-3 mix apps"
                      data-aos="fade-in"
                    >
                      <div className="photo">
                        <LazyLoadComponent>
                          <ModalImage
                            alt={image.title}
                            small={`${baseUrl}${image.src}`}
                            large={`${baseUrl}${image.src}`}
                          />
                        </LazyLoadComponent>
                        {/* <div className="overlay">
                            <div className="zoom">
                              <p>View Image</p>
                            </div>
                          </div> */}
                      </div>
                    </div>
                  </React.Fragment>
                ))}

                {data["images"]["logo"].map((image, idx) => (
                  <React.Fragment key={idx}>
                    <div
                      className="col-md-3 col-sm-3 mix logo"
                      data-aos="fade-in"
                    >
                      <div className="photo">
                        <LazyLoadComponent>
                          <ModalImage
                            alt={image.title}
                            small={`${baseUrl}${image.src}`}
                            large={`${baseUrl}${image.src}`}
                          />
                        </LazyLoadComponent>
                        {/* <div className="overlay">
                            <div className="zoom">
                              <p>View Image</p>
                            </div>
                          </div> */}
                      </div>
                    </div>
                  </React.Fragment>
                ))}

                {data["images"]["mockup"].map((image, idx) => (
                  <React.Fragment key={idx}>
                    <div
                      className="col-md-3 col-sm-3 mix mockup"
                      data-aos="fade-in"
                    >
                      <div className="photo">
                        <LazyLoadComponent>
                          <ModalImage
                            alt={image.title}
                            small={`${baseUrl}${image.src}`}
                            large={`${baseUrl}${image.src}`}
                          />
                        </LazyLoadComponent>
                        {/* <div className="overlay">
                            <div className="zoom">
                              <p>View Image</p>
                            </div>
                          </div> */}
                      </div>
                    </div>
                  </React.Fragment>
                ))}

                {data["images"]["print"].map((image, idx) => (
                  <React.Fragment key={idx}>
                    <div
                      className="col-md-3 col-sm-3 mix print"
                      data-aos="fade-in"
                    >
                      <div className="photo">
                        <LazyLoadComponent>
                          <ModalImage
                            alt={image.title}
                            small={`${baseUrl}${image.src}`}
                            large={`${baseUrl}${image.src}`}
                          />
                        </LazyLoadComponent>
                        {/* <div className="overlay">
                            <div className="zoom">
                              <p>View Image</p>
                            </div>
                          </div> */}
                      </div>
                    </div>
                  </React.Fragment>
                ))}

                {data["images"]["advertise"].map((image, idx) => (
                  <React.Fragment key={idx}>
                    <div
                      className="col-md-3 col-sm-3 mix misc"
                      data-aos="fade-in"
                    >
                      <div className="photo">
                        <LazyLoadComponent>
                          <ModalImage
                            alt={image.title}
                            small={`${baseUrl}${image.src}`}
                            large={`${baseUrl}${image.src}`}
                          />
                        </LazyLoadComponent>
                        {/* <div className="overlay">
                            <div className="zoom">
                              <p>View Image</p>
                            </div>
                          </div> */}
                      </div>
                    </div>
                  </React.Fragment>
                ))}

                {data["images"]["character"].map((image, idx) => (
                  <React.Fragment key={idx}>
                    <div
                      className="col-md-3 col-sm-3 mix misc"
                      data-aos="fade-in"
                    >
                      <div className="photo">
                        <LazyLoadComponent>
                          <ModalImage
                            alt={image.title}
                            small={`${baseUrl}${image.src}`}
                            large={`${baseUrl}${image.src}`}
                          />
                        </LazyLoadComponent>
                        {/* <div className="overlay">
                            <div className="zoom">
                              <p>View Image</p>
                            </div>
                          </div> */}
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
