import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <section id="about" className="about-section">
        <div className="about">
          <div className="container">
            <div className="row flex-column">
              <h2 className="heading-section" data-aos="fade-up">
                About Me
              </h2>
              <div className="seperator"></div>

              <div className="col-lg-12 col-md-12 content mt-3">
                <span data-aos="fade-in">
                  I'm a{" "}
                  <span
                    style={{
                      background: "#EF255F",
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#fff",
                      padding: "5px 15px",
                      lineHeight: "1 !important",
                      margin: 0,
                      borderRadius: "20px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Front-End Developer
                  </span>{" "}
                  {"&"}{" "}
                  <span
                    style={{
                      background: "#ffc107",
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#fff",
                      padding: "5px 15px",
                      lineHeight: "1 !important",
                      margin: 0,
                      borderRadius: "20px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    UI/UX Designer
                  </span>{" "}
                  with more than 12 years of expertise in blending the art of design with skill of programming to deliver an immersive and engaging user experience. Energetic and passionate about building clean, elegant and efficient web applications. Experience working collaboratively with a globally distributed team. Responsible for analyse various frontend technologies, frameworks, libraries to design an ideal approach and define better architecture based on client requirements.
                </span>

                <div className="row" data-aos="fade-in">
                  <div className="col-lg-6 col-sm-6 mt-4">
                    <h4>HTML 5</h4>
                    <div className="skill show-9 d-flex">
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                    </div>
                    <h4>CSS3(SCSS {"&"} LESS)</h4>
                    <div className="skill show-9 d-flex">
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                    </div>
                    <h4>Javascript</h4>
                    <div className="skill show-8 d-flex">
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                    </div>
                    <h4>React.js</h4>
                    <div className="skill show-8 d-flex">
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                    </div>
                    <h4>Angular 6/9/11</h4>
                    <div className="skill show-6 d-flex">
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                    </div>
                    <h4>Bootstrap 3/4</h4>
                    <div className="skill show-9 d-flex">
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                    </div>
                    <h4>jQuery</h4>
                    <div className="skill show-8 d-flex">
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                    </div>
                    <h4>Wordpress</h4>
                    <div className="skill show-6 d-flex">
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 mt-4">
                    <h4>Adobe Photoshop</h4>
                    <div className="skill2 show-9 d-flex">
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                    </div>
                    <h4>Illustrator</h4>
                    <div className="skill2 show-9 d-flex">
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                    </div>
                    <h4>Adobe XD</h4>
                    <div className="skill2 show-9 d-flex">
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                    </div>
                    <h4>Sketch</h4>
                    <div className="skill2 show-8 d-flex">
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                    </div>
                    <h4>Figma</h4>
                    <div className="skill2 show-8 d-flex">
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                    </div>
                    <h4>After Effects</h4>
                    <div className="skill2 show-7 d-flex">
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                    </div>
                    <h4>Premiere Pro</h4>
                    <div className="skill2 show-8 d-flex">
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                    </div>
                    <h4>Cinema 4D</h4>
                    <div className="skill2 show-6 d-flex">
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
