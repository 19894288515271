import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class Quotes extends Component {
  render() {
    return (
      <section id="quotations" className="section-quotations">
        <div className="quotations" data-aos="fade-in">
          <div className="container">
            <div className="row flex-column">
              <OwlCarousel
                className="quotations-carousel"
                loop
                items={1}
                autoplay={true}
              >
                <div className="quote item">
                  <div className="col-md-12 quote-text">
                    <div className="icon-left">
                      <i
                        className="fa fa-quote-left fa-4x"
                        aria-hidden="true"
                        style={{ color: "#EF255F" }}
                      ></i>
                    </div>
                    <p className="quote-item" style={{ color: "#000" }}>
                      I make all my decisions on intuition. I throw a spear into
                      the darkness. That is intuition. Then I must send an army
                      into the darkness to find the spear. That is intellect.
                    </p>
                    <p
                      className="text-right"
                      style={{ color: "#000", fontSize: "18px" }}
                    >
                      -{" "}
                      <span
                        style={{ color: "#000", textDecoration: "underline" }}
                      >
                        Ingmar Bergman
                      </span>
                    </p>
                    <div className="icon-right">
                      <i
                        className="fa fa-quote-right fa-4x"
                        aria-hidden="true"
                        style={{ color: "#EF255F" }}
                      ></i>
                    </div>
                  </div>
                </div>

                <div className="quote item">
                  <div className="col-md-12 quote-text">
                    <div className="icon-left">
                      <i
                        className="fa fa-quote-left fa-4x"
                        aria-hidden="true"
                        style={{ color: "#EF255F" }}
                      ></i>
                    </div>
                    <p className="quote-item" style={{ color: "#000" }}>
                      The reason it seems that price is all your customers' care
                      about is that you haven't given them anything else to care
                      about.
                    </p>
                    <p
                      className="text-right"
                      style={{ color: "#000", fontSize: "18px" }}
                    >
                      -{" "}
                      <span
                        style={{ color: "#000", textDecoration: "underline" }}
                      >
                        Seth Godin
                      </span>
                    </p>
                    <div className="icon-right">
                      <i
                        className="fa fa-quote-right fa-4x"
                        aria-hidden="true"
                        style={{ color: "#EF255F" }}
                      ></i>
                    </div>
                  </div>
                </div>

                <div className="quote item">
                  <div className="col-md-12 quote-text">
                    <div className="icon-left">
                      <i
                        className="fa fa-quote-left fa-4x"
                        aria-hidden="true"
                        style={{ color: "#EF255F" }}
                      ></i>
                    </div>
                    <p className="quote-item" style={{ color: "#000" }}>
                      Want to grow as a designer? Spend most of your time
                      thinking about how it works, as opposed to how it looks.
                    </p>
                    <p
                      className="text-right"
                      style={{ color: "#000", fontSize: "18px" }}
                    >
                      -{" "}
                      <span
                        style={{ color: "#000", textDecoration: "underline" }}
                      >
                        Josh Puckett
                      </span>
                    </p>
                    <div className="icon-right">
                      <i
                        className="fa fa-quote-right fa-4x"
                        aria-hidden="true"
                        style={{ color: "#EF255F" }}
                      ></i>
                    </div>
                  </div>
                </div>

                {/* <div className="owl-controls clickable">
                <div className="owl-pagination">
                  <div className="owl-page">
                    <span className=""></span>
                  </div>
                  <div className="owl-page">
                    <span className=""></span>
                  </div>
                  <div className="owl-page active">
                    <span className=""></span>
                  </div>
                </div>
              </div> */}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Quotes;
