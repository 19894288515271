import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="container">
          <div className="row align-items-center" data-aos="fade-in">
            <div className="col-lg-6 col-md-12">
              <div className="copyright">
                <p>
                  &copy; {new Date().getFullYear()} Made with{" "}
                  <i
                    className="fa fa-heart"
                    aria-hidden="true"
                    style={{ color: "#FCCF4D" }}
                  ></i>{" "}
                  by <a href="https://www.hellojagath.com">Jagath Jayakumar</a>
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="social-media">
                <ul>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/jagath-jayakumar-51458a26/"
                      title="Linkedin"
                      aria-label="Linkedin"
                    >
                      <i className="fa fa-linkedin" aria-hidden="false"></i>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://twitter.com/Jagathgj"
                      title="twitter"
                      aria-label="Twitter"
                    >
                      <i className="fa fa-twitter" aria-hidden="false"></i>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.facebook.com/jagath.gj"
                      title="Facebook"
                      aria-label="Facebook"
                    >
                      <i className="fa fa-facebook" aria-hidden="false"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
