import React, { Component } from "react";
import Axios from "axios";
import baseURL from "../../api/GitHub";
import RepoCard from "./RepoCard";

class GitHubCards extends Component {
  Title = [];
  state = {
    repo: [],
    language: [],
  };

  async componentDidMount() {
    const api_key = process.env.REACT_APP_API_KEY;

    let repo = [
      baseURL(`html-sass-bootstrap-gulp-multipage-boilerplate`),
      baseURL(`shopping`),
      baseURL(`React-Dashboard`),
      baseURL(`react-dropper`),
    ];

    await Axios.get("https://github-lang-deploy.herokuapp.com/").then(
      async (res) =>
        await this.setState({ language: res && res ? res.data : "" })
    );
    repo.map(
      async (url) =>
        await Axios.get(url, {
          headers: {
            Authorization: `token ${api_key}`,
          },
        }).then(async (res) => {
          await this.setState({
            repo: [...this.state.repo, res.data],
          });
        })
    );
  }
  render() {
    const { repo, language } = this.state;
    repo.sort((a, b) =>
      a.stargazers_count > b.stargazers_count
        ? -1
        : b.stargazers_count > a.stargazers_count
        ? 1
        : 0
    );
    return (
      <>
        {repo !== null && (
          <section id="about" className="about-section repo-section">
            <div className="about pb-5">
              <div className="container">
                <div className="row flex-column">
                  <h2 className="heading-section" data-aos="fade-up">
                    Public Repos
                  </h2>
                  <div className="seperator"></div>

                  <div className="col-lg-12 col-md-12 content">
                    <div className="row">
                      {repo.map((data, i) => (
                        <RepoCard repo={data} key={i} language={language} />
                      ))}
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <div
                          className="d-flex justify-content-center mt-5"
                          data-aos="fade-in"
                        >
                          <a
                            href="https://github.com/jagathgj"
                            className="d-flex prime-btn align-items-center"
                          >
                            <div className="flex-fill mr-3">
                              See Github Profile
                            </div>
                            <i className="fa fa-space-shuttle faa-passing animated"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    );
  }
}
export default GitHubCards;
