import React, { Component } from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareCount,
} from "react-share";

class Context extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    document.addEventListener("contextmenu", this._handleContextMenu);
    document.addEventListener("click", this._handleClick);
    document.addEventListener("scroll", this._handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("contextmenu", this._handleContextMenu);
    document.removeEventListener("click", this._handleClick);
    document.removeEventListener("scroll", this._handleScroll);
  }

  _handleContextMenu = (event) => {
    event.preventDefault();

    this.setState({ visible: true });

    const clickX = event.clientX;
    const clickY = event.clientY;
    const screenW = window.innerWidth;
    const screenH = window.innerHeight;
    const rootW = this.root.offsetWidth;
    const rootH = this.root.offsetHeight;

    const right = screenW - clickX > rootW;
    const left = !right;
    const top = screenH - clickY > rootH;
    const bottom = !top;

    if (right) {
      this.root.style.left = `${clickX + 5}px`;
    }

    if (left) {
      this.root.style.left = `${clickX - rootW - 5}px`;
    }

    if (top) {
      this.root.style.top = `${clickY + 5}px`;
    }

    if (bottom) {
      this.root.style.top = `${clickY - rootH - 5}px`;
    }
  };

  _handleClick = (event) => {
    const { visible } = this.state;
    const wasOutside = !(event.target.contains === this.root);

    if (wasOutside && visible) this.setState({ visible: false });
  };

  _handleScroll = () => {
    const { visible } = this.state;

    if (visible) this.setState({ visible: false });
  };

  handleGithubLink = () => {
    window.open("https://github.com/jagathgj", "_blank");
  };

  handleMedium = () => {
    window.open(
      "https://medium.com/@jagathjayakumar/html-boilerplate-with-bootstrap-4-gulp-4-sass-and-browsersync-c49d6852681e",
      "_blank"
    );
  };

  render() {
    const { visible } = this.state;
    const { nightModeStatus } = this.props;
    return (
      (visible || null) && (
        <div
          ref={(ref) => {
            this.root = ref;
          }}
          className="contextMenu"
        >
          <FacebookShareButton url="https://hellojagath.com">
            <div className="contextMenu--option">Share this</div>
          </FacebookShareButton>
          <div
            className="contextMenu--option"
            onClick={this.props.handleNightMode}
          >
            {nightModeStatus && nightModeStatus ? "Day Mode" : "Night Mode"}
          </div>
          <div className="contextMenu--option" onClick={this.handleGithubLink}>
            Github Profile
          </div>
          {/* contextMenu--option__disabled */}
          <div className="contextMenu--option" onClick={this.handleMedium}>
            Latest Medium
          </div>
          <div className="contextMenu--option">Playground</div>
          <div className="contextMenu--separator" />
          <div className="contextMenu--option">About this website</div>
        </div>
      )
    );
  }
}

export default Context;
