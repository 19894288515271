import React, { Component } from "react";

class Expertise extends Component {
  render() {
    return (
      <section id="services" className="section-services">
        <div className="services overlay-parallax">
          <div className="container">
            <div className="row flex-column">
              <div className="col-md-12">
                <h2
                  className="heading-section"
                  style={{ color: "#EF255F" }}
                  data-aos="fade-up"
                >
                  Expertise
                </h2>
                <div className="seperator"></div>
                <p
                  className="sub-title"
                  style={{ color: "#fff" }}
                  data-aos="fade-in"
                >
                  I think the definition of a designer is someone that lives and
                  breathes design. Things like recognizing fonts on posters, not
                  buying something because it’s not symmetric, always on top of
                  designs and always eager to learn new stuff.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-4">
                <div className="services-row">
                  <div className="block-service border1" data-aos="zoom-in">
                    <div className="row icon-text align-items-center h-100">
                      <div className="col-md-3 icon">
                        <i
                          className="fa fa-desktop fa-4x faa-tada animated"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="col-md-9 text">
                        <h4>Web Development</h4>
                        <p>
                          Responsive is not a word for developer. It's a
                          responsiblity.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="block-service2 border2" data-aos="zoom-in">
                    <div className="row icon-text align-items-center h-100">
                      <div className="col-md-3 icon">
                        <i
                          className="fa fa-tablet fa-5x faa-ring animated"
                          aria-hidden="true"
                          //   style={{ fontSize: "80px" }}
                        ></i>
                      </div>
                      <div className="col-md-9 text">
                        <h4>UX {"&"} UI design</h4>
                        <p>
                          Its not those pretty colors, but the experience I
                          prefer most.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-4">
                <div className="services-row">
                  <div className="block-service border22" data-aos="zoom-in">
                    <div className="row icon-text align-items-center h-100">
                      <div className="col-md-3 icon">
                        <i
                          className="fa fa-paint-brush fa-4x faa-float animated"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="col-md-9 text">
                        <h4>Graphic Design</h4>
                        <p style={{ color: "#fff" }}>
                          Logos, Banners, Icons, Prints - Let my pen tool
                          guides.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="block-service2 border44" data-aos="zoom-in">
                    <div className="row icon-text align-items-center h-100">
                      <div className="col-md-3 icon">
                        <i
                          className="fa fa-play fa-4x faa-passing animated"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="col-md-9 text">
                        <h4>Motion design</h4>
                        <p style={{ color: "#fff" }}>
                          Explainer and corporate videos in a creative and
                          imaginative way.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-4">
                <div className="services-row">
                  <div className="block-service border3" data-aos="zoom-in">
                    <div className="row icon-text align-items-center h-100">
                      <div className="col-md-3 icon">
                        <i
                          className="fa fa-flask fa-4x faa-bounce animated"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="col-md-9 text">
                        <h4>Product Design</h4>
                        <p>
                          I do craft, design, test and ship the solution for
                          your problem.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="block-service2 border4" data-aos="zoom-in">
                    <div className="row icon-text align-items-center h-100">
                      <div className="col-md-3 icon">
                        <i
                          className="fa fa-video-camera fa-4x faa-flash animated"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="col-md-9 text">
                        <h4>Ad film Making</h4>
                        <p>Commercial Ad films. Short films. </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Expertise;
